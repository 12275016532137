import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"

//import icons
import IconRightArrow from "./../../assets/icons/svg/arrow-point-to-right.svg"
import OdownLogo from "./../../assets/logo/logo.svg"
import StatusCakeLogo from "./../../assets/icons/svg/statuscake-logo.svg"
import AvailableFeatureIcon from "../../assets/icons/svg/tick.svg"
import UnavailableFeatureIcon from "../../assets/icons/svg/cancel.svg"

//import css
import "./../../styles/alternatives-plateforms.css"

//import components
import GetStartedBanner from "../../components/get-started-footer"
import PricingComparator from "../../components/competitor-price-comparator"
import StatusCakeAlternative from "../../components/StatusCakeAlternative"


const Header = ({ data }) => {
  return (
    <section className="alt-header-wrapper">
      <div className="container">
        <div className="alt-header__title">
          <h1>Best Alternative To StatusCake</h1>
        </div>
        <div className="alt-header__description">
          <p>
            If you’re looking for an alternative to StatusCake, then Odown is
            the perfect website monitoring service for you.
          </p>
        </div>
        <div className="alt-header__button">
          <OutboundLink
            className="btn-primary"
            eventCategory="Launch the Odown App"
            eventAction="Click"
            eventLabel="Goto signup"
            href="https://app.odown.io/signup"
          >
            Start monitoring <IconRightArrow />
          </OutboundLink>
        </div>
      </div>
    </section>
  )
}

const FeaturesComparator = ({ data }) => {
  const featuresDiff = [
    {
      name: "Beautiful dashboard",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Beautiful dashboard" },
        competitor: { sign: false, name: "Bad dashboard design" },
      },
    },
    {
      name: "Designed for teams and enterprises ",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Designed for teams and enterprises " },
        competitor: {
          sign: false,
          name: "Designed for teams and enterprises ",
        },
      },
    },
    {
      name: "Built-in incident management",
      owner: true,
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Built-in incident management" },
        competitor: { sign: false, name: "Built-in incident management" },
      },
    },
    {
      name: "Built-in website monitoring",
      owner: true,
      competitor: true,
      mobile: {
        owner: { sign: true, name: "Built-in website monitoring" },
        competitor: { sign: true, name: "Built-in website monitoring" },
      },
    },
    {
      name: "Built-in SSL monitoring",
      owner: true,
      competitor: true,
      mobile: {
        owner: { sign: true, name: "Built-in SSL monitoring" },
        competitor: { sign: true, name: "Built-in SSL monitoring" },
      },
    },
    // {
    // 	name : "Team members collaboration" ,
    // 	owner : "Start at basic plan for $9.99/month" ,
    // 	competitor : "Start at $110/month"
    // },
    {
      name: "Customer service",
      owner: "Fast and available every day",
      competitor: false,
      mobile: {
        owner: { sign: true, name: "Fast customer service" },
        competitor: { sign: false, name: "Slow customer service" },
      },
    },
  ]

  return (
    <section className="features-comparator-table-wrapper">
      <div className="container">
        <h2 className="title">Odown vs StatusCake - A Snapshot</h2>
        <div className="content">
          <div className="diff-features">
            <div className="col-side-items">
              <h2>Odown vs. StatusCake</h2>
              <ul>
                {featuresDiff.map((item, index) => (
                  <li key={index}>{item.name}</li>
                ))}
              </ul>
            </div>
            <div className="col-providers">
              <div className="col-provider owner-service">
                <div className="logo">
                  <OdownLogo />
                </div>
                <ul>
                  {featuresDiff.map((item, index) => (
                    <React.Fragment key={index}>
                      <li>
                        {typeof item.owner === "boolean" ? (
                          item.owner === true ? (
                            <AvailableFeatureIcon className="available-feature" />
                          ) : (
                            <UnavailableFeatureIcon className="unavailable-feature" />
                          )
                        ) : (
                          item.owner
                        )}
                      </li>
                      <li className="v-mob__featureItem">
                        {typeof item.mobile.owner.sign === "boolean" ? (
                          item.mobile.owner.sign === true ? (
                            <AvailableFeatureIcon className="available-feature" />
                          ) : (
                            <UnavailableFeatureIcon className="unavailable-feature" />
                          )
                        ) : (
                          item.mobile.owner.sign
                        )}
                        <span>{item.mobile.owner.name}</span>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
              <div className="col-provider competitor-service">
                <div className="logo">
                  <StatusCakeLogo />
                </div>
                <ul>
                  {featuresDiff.map((item, index) => (
                    <React.Fragment key={index}>
                      <li>
                        {typeof item.competitor === "boolean" ? (
                          item.competitor === true ? (
                            <AvailableFeatureIcon className="available-feature" />
                          ) : (
                            <UnavailableFeatureIcon className="unavailable-feature" />
                          )
                        ) : (
                          item.competitor
                        )}
                      </li>
                      <li className="v-mob__featureItem">
                        {typeof item.mobile.competitor.sign === "boolean" ? (
                          item.mobile.competitor.sign === true ? (
                            <AvailableFeatureIcon className="available-feature" />
                          ) : (
                            <UnavailableFeatureIcon className="unavailable-feature" />
                          )
                        ) : (
                          item.mobile.competitor.sign
                        )}
                        <span>{item.mobile.competitor.name}</span>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*}
 */
const Main = ({ data }) => (
  <Layout>
    <SEO
      title="Best Alternative To StatusCake"
      description="If you're looking for an alternative to StatusCake, then Odown is the perfect website monitoring service for you."
      pathname={`/alternatives/statuscake`}
    />
    <Header />
    <FeaturesComparator />
    <PricingComparator data={{ selectedUptimeTool: "statuscake" }} />
    <StatusCakeAlternative />

    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default Main
